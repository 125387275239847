<template>
  <div class="full-page">
    <div class="header">
      <img src="@/assets/head-bg.png">
      <div class="department-name"><span>选择科室</span></div>
    </div>
    <div class="department-column">
      <div class="department-list">
        <div class="department-item" v-for="(item, index) in departmentList" :key="index" @click="toDoctorList(item)">
          <img src="@/assets/department-bg.png">
          <div class="department-item-name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        departmentList: []
      }
    },
    
    created() {
      this.getDdepartmentList()
    },

    methods: {
      getDdepartmentList () {
        this.$request({
          url: '/department/list',
          data: {pageSize: 100}
        }).then( res => {
          this.departmentList = res.result.rows
        })
      },

      toDoctorList ( row ) {
        this.$store.commit('setDepartment', row.name)
        this.$router.push({path: '/doctor', name: 'doctor', params: {id: row.id}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    .header {
      img {
        width: 100%;
      }
      
      .department-name {
        font-size: .36rem;
        color: #fff;
        position: absolute;
        left: .24rem;
        top: .3rem;
      }
    }

    .department-column {
      padding: .24rem;
      margin-top: -1.14rem;
      position: relative;
    }

    .department-list {
      border-radius: .08rem;

      .department-item {
        background: #FFFFFF;
        margin-bottom: .24rem;
        font-size: .36rem;
        font-weight: bold;
        border-radius: .1rem;
        overflow: hidden;
        position: relative;
        .department-item-name {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          width: 100%;
          display: block;
        }
      }
    }

  }
</style>